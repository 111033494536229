import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'BOL',
    decimal: 18,
    lpAddresses: {
      137: '0x9f897324aaf3a6426165341ff1747688151e604c' // BOL-USDT LP
    },
    tokenSymbol: 'BOL',
    tokenAddresses: {
      137: '0x8b661d59f1923b138e0048ff5ed5b8fef6f9e5cf'
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'MATIC-BOL LP',
    decimal: 18,
    lpAddresses: {
      137: '0xd6982e01d183b3a979c3294c2f48416458f0e19c', // BOL-MATIC LP
    },
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'BOL-USDT LP',
    decimal: 18,
    lpAddresses: {
      137: '0x9f897324aaf3a6426165341ff1747688151e604c', // BOL-USDT LP
    },
    tokenSymbol: 'BOL',
    tokenAddresses: {
      137: '0x8b661d59f1923b138e0048ff5ed5b8fef6f9e5cf',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 3,
    risk: 2,
    lpSymbol: 'MATIC-USDT LP',
    decimal: 18,
    lpAddresses: {
      137: '0x82188c21263a893bf34d7b59ca56d04e0a0727fe', // USDT/MATIC LP
    },
    tokenSymbol: 'MATIC',
    tokenAddresses: {
      137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 4,
    risk: 2,
    lpSymbol: 'USDC-USDT LP',
    decimal: 18,
    lpAddresses: {
      137: '0x8278d6c8c0e4bcf9afb60d36f6b075ade1492982', // USDC-USDT LP
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'BLOK-BOL LP',
    decimal: 18,
    lpAddresses: {
      137: '0xe5333cf1df2f8d181db24e8805f0894fe3050972', // BOl-BLOK LP
    },
    tokenSymbol: 'BLOK',
    tokenAddresses: {
      137: '0x229b1b6c23ff8953d663c4cbb519717e323a0a84',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 6,
    risk: 5,
    lpSymbol: 'BLOK-USDT LP',
    decimal: 18,
    lpAddresses: {
      137: '0x635cc89989282F8cDc2BE76ce0052D4d0885310E', // USDT-BLOK LP
    },
    tokenSymbol: 'BLOK',
    tokenAddresses: {
      137: '0x229b1b6c23ff8953d663c4cbb519717e323a0a84',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 7,
    risk: 5,
    lpSymbol: 'POLYDOGE-BOL LP',
    decimal: 18,
    lpAddresses: {
      137: '0x43b2e41bb9b2CA91C2c1C9865c6bD36f2295d891', // BOl-POLYDOGE- LP
    },
    tokenSymbol: 'POLYDOGE',
    tokenAddresses: {
      137: '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'POLYDOGE-USDT LP',
    decimal: 18,
    lpAddresses: {
      137: '0xebe3a0130fb171d55811d2699d936ae55cf03ca2', // USDT-POLYDOGE- LP
    },
    tokenSymbol: 'POLYDOGE',
    tokenAddresses: {
      137: '0x8A953CfE442c5E8855cc6c61b1293FA648BAE472',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
  {
    pid: 9,
    risk: 5,
    lpSymbol: 'MANA-BOL LP',
    decimal: 18,
    lpAddresses: {
      137: '0xf4e61f69931e8f9cd51859f60cb841ee50c5a433', // BOl-MANA LP
    },
    tokenSymbol: 'MANA',
    tokenAddresses: {
      137: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    },
    quoteTokenSymbol: QuoteToken.BOL,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 10,
    risk: 5,
    lpSymbol: 'MANA-USDT LP',
    decimal: 18,
    lpAddresses: {
      137: '0x0c100343ad704aa81bb50f2f15a8bf9b2e55e3c9', // USDT-MANA LP
    },
    tokenSymbol: 'MANA',
    tokenAddresses: {
      137: '0xA1c57f48F0Deb89f569dFbE6E2B7f46D33606fD4',
    },
    quoteTokenSymbol: QuoteToken.USDT,
    quoteTokenAdresses: contracts.usdt,
  },
]

export default farms
