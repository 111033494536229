export default {
  cake: {
    137: '0x8b661d59f1923b138e0048ff5ed5b8fef6f9e5cf'
  },
  masterChef: {
    137: '0x6ee4b5b4787e65704819bad006a6c392be232b2d',
  },
  weth: {
    137: "0x7ceb23fd6bc0add59e62ac25578270cff1b9f619"
  },
  lottery: {
    56: '',
    97: '',
  },
  lotteryNFT: {
    56: '',
    97: '',
  },
  mulltiCall: {
    137: '0x57ac6df707ca70741d23ff1587a3d71f536c0b07'
  },
  usdc: {
    137: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174'
  },
  usdt: {
    137: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f'
  },
  wmatic: {
    137: '0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270'
  },
  matic: {
    137: '0x0000000000000000000000000000000000001010'
  }
}
